import "../styles/globals.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SSRProvider from "react-bootstrap/SSRProvider";
import { zhCN } from "@mui/material/locale";
import Layout from "../Components/Layout";
import { useEffect, useState } from "react";
import Script from "next/script";
import { useRouter } from "next/router";
import * as gtag from "../lib/gtag";
import * as fbq from "../lib/fpixel";
import { DefaultSeo } from "next-seo";
import { keyword } from "../Data/keyword";
// import your default seo configuration
import SEO from "../next-seo.config";
import Head from "next/head";
import CustomLoader from "../Components/CustomLoader/CustomLoader";

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  zhCN
);

function MyApp({ Component, pageProps }) {
  //---AdSense code start ---//
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("hashChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("hashChangeComplete", handleRouteChange);
    };
  }, [router.events]);
  //---AdSense code end ---//

  //---Facebook Pixel Start---//
  useEffect(() => {
    fbq.pageview();
    const handleRouteChange = () => {
      fbq.pageview();
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
  //---Facebook Pixel End---//

  //---User Token Check Start---//
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const userInfo =
      localStorage.getItem("auth_token") !== "undefined"
        ? localStorage.getItem("auth_token")
        : null;
    if (!userInfo) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  });
  //---User Token Check End---//

  //---Page to Page Loader

  function Loading() {
    const router = useRouter();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const handleStart = (url) => url !== router.asPath && setLoading(true);
      const handleComplete = (url) =>
        url === router.asPath && setLoading(false);

      router.events.on("routeChangeStart", handleStart);
      router.events.on("routeChangeComplete", handleComplete);
      router.events.on("routeChangeError", handleComplete);

      return () => {
        router.events.off("routeChangeStart", handleStart);
        router.events.off("routeChangeComplete", handleComplete);
        router.events.off("routeChangeError", handleComplete);
      };
    });
    return loading && <CustomLoader />;
  }

  return (
    <>
      {/*--- Default SEO ---*/}
      <Head>
        <link rel="icon" href="/favicon.svg" />
        <meta
          name="keywords"
          content="research, what is research,research questions,research problem,research questions examples,reasearch problem example,research methods,research synonym,how to make a research,how to make a research proposal,how to make a research paper,how to make a research title examples,how to make a research questions,how to make a research poster,how to make a research design,how to make a research introduction,how to make a reservstion,research paper,research proposal sample,researchgate log in,research design,research methodology,research proposal,researchgate mohammad mokaddes ali,research4life login,how to make a research paper,how to make a research question,how to make a research poster,how to make a research,how to make a reservation,how to make a research proposal,how to make a reservation for permit test,how to make a reset builds button fortnite,how to make a research title,how to make a resume"
        />
        <meta name="yandex-verification" content="8bf23a4621c9797b" />
      </Head>
      <DefaultSeo {...SEO} />

      {/* Adsbygoogle - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />

      {/* Global Site Code Pixel - Facebook Pixel */}
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
        }}
      />

      <ThemeProvider theme={theme}>
        <Layout isLoggedIn={isLoggedIn}>
          <SSRProvider>
            <Loading />
            <Component
              {...pageProps}
              isLoggedIn={isLoggedIn}
              adsId={gtag.CA_PUB}
            />
          </SSRProvider>
        </Layout>
      </ThemeProvider>
    </>
  );
}

export default MyApp;
