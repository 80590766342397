import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Typography,
  Avatar,
  Box,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import Swal from "sweetalert2";
import * as constants from "../../constants";
import { useRouter } from "next/router";
import Link from "next/link";
import CourseSignUp from "../CourseSignUp/CourseSignUp";
import { styled } from "@mui/material/styles";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link
        style={{ textDecoration: "none" }}
        color="inherit"
        href="https://researchrider.com/"
      >
        www.researchrider.com
      </Link>
    </Typography>
  );
}

export default function SignUpModal({
  open,
  handleClose,
  handleOpen,
  courseID,
  toggle,
}) {
  const [showResults, setShowResults] = React.useState(true);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("xs");
  const baseURL = constants.BASE_URL;

  const ToggleSignUp = () => {
    setShowResults(true);
  };

  // states for login
  const [username, setUsername] = React.useState("");
  const [passForLogin, setPassForLogin] = React.useState("");

  // login function
  const loginHeader = {
    mode: "cors",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: username,
      password: passForLogin,
    }),
  };

  const router = useRouter();

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(`${baseURL}/user/signin`, loginHeader)
      .then((response) => response.json())
      .then((data) => {
        if (data?.data?.is_active) {
          window.localStorage.setItem("id", data.data.id);
          window.localStorage.setItem("first_name", data.data.first_name);
          window.localStorage.setItem("last_name", data.data.last_name);
          window.localStorage.setItem("email", data.data.email);
          window.localStorage.setItem("auth_token", data.data.token);
          {
            courseID
              ? router.push(`/course/payment/${courseID}`)
              : router.push(`/user/${localStorage.id}`);
          }
          handleClose();
        } else if (data.data.is_active === false) {
          Swal.fire({
            title:
              "You haven't verified your email. Please verified your email first",
            icon: "warning",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "Ok",
          });
          router.push("/user/verification");
        }
      })
      .catch((error) => {
        Swal.fire({
          title: `we don't recognize that username or password`,
          icon: "warning",
          confirmButtonColor: "#ff0000",
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <React.Fragment>
      <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open}>
        <DialogTitle className="d-flex flex-column align-items-center">
          {toggle ? (
            <>
              <ModalHeaderWrapper>
                <h2 className="p-0 m-0">
                  Connect to access over a million publication pages
                </h2>
              </ModalHeaderWrapper>
            </>
          ) : (
            <>
              {showResults ? (
                <>
                  <Avatar sx={{ bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h4" variant="h6">
                    Sign in
                  </Typography>
                </>
              ) : (
                <>
                  <Avatar sx={{ bgcolor: "secondary.main" }}>
                    <HowToRegIcon />
                  </Avatar>
                  <Typography component="h4" variant="h6">
                    Sign up
                  </Typography>
                </>
              )}
            </>
          )}
        </DialogTitle>

        <DialogContent>
          <>
            {showResults ? (
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{
                  my: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <TextField
                  margin="normal"
                  required
                  label="Email Address"
                  autoComplete="email"
                  className="w-100"
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  className="w-100"
                  onChange={(e) => setPassForLogin(e.target.value)}
                />

                <Button
                  className="w-100"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Sign In
                </Button>
              </Box>
            ) : (
              <CourseSignUp
                handleClose={handleClose}
                ToggleSignUp={ToggleSignUp}
              />
            )}

            <Grid container sx={{ cursor: "pointer" }}>
              {showResults ? (
                <Grid item onClick={() => setShowResults(false)}>
                  <p>
                    New to Research Rider?{" "}
                    <span className="text-primary">Sign up</span>
                  </p>
                </Grid>
              ) : (
                <Grid item onClick={() => setShowResults(true)}>
                  <p>
                    Already on Research Rider?{" "}
                    <span className="text-primary">Log in</span>
                  </p>
                </Grid>
              )}
            </Grid>
            <Copyright sx={{ mt: 2 }} />
          </>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            sx={{ textTransform: "none" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const ModalHeaderWrapper = styled("div")({
  backgroundColor: "#1976d2",
  padding: "20px",
  borderRadius: "0 0 50% 50%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  "& h2": {
    margin: 0,
    fontSize: "16px",
    fontWeight: 600,
    letterSpacing: "2px",
    color: "#fff",
    fontFamily: "Montserrat, sans-serif",
  },
});
