import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Box,
  TextField,
  Button,
  Grid,
  OutlinedInput,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import logo from "../../../assets/landing/Banner-logo.svg";
import * as constants from "../../constants";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Image from "next/image";
import style from "./CourseSignUp.module.css";

const steps = [
  {
    label: "Sign Up",
    helper: "Please fill in this form to create an account.",
  },
  {
    label: "Account Details",
    helper: "Please fill in this form to create an account.",
  },
];

const CourseSignUp = ({ handleClose, ToggleSignUp }) => {
  const baseURL = constants.BASE_URL;
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //Verification Modal
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("xs");
  const [token, setToken] = React.useState("");
  const [openVerifi, setVerifi] = React.useState(false);
  const handleVerifiClose = () => {
    setVerifi(false);
  };

  // states for registration
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [academic, setAcademicDis] = useState("");
  const [organization, setOrganization] = useState("");
  const [email, setEmail] = useState("");
  const [profession, setProfession] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [gender, setGender] = React.useState("");
  //Date of Birth
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [monthOfBirth, setMonthOfBirth] = useState("");
  const [yearOfBirth, setYearOfBirth] = useState("");

  const registrationHeader = {
    // mode: 'no-cors',
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      first_name: firstName,
      last_name: "",
      mobile_no: phone,
      email: email,
      profession: profession,
      password: password,
      confirmPassword: confirmPassword,
      organization: organization,
      academic_discipline: academic,
      user_full_name: fullName,
      birthdate: yearOfBirth + "-" + monthOfBirth + "-" + dateOfBirth,
      monthOfBirth: monthOfBirth,
      yearOfBirth: yearOfBirth,
      gender: gender,
    }),
  };

  const handleRegistration = (event) => {
    event.preventDefault();

    fetch(`${baseURL}/user/registration`, registrationHeader)
      .then((response) => {
        response.json();
        if (response.status === 201) {
          //   ToggleSignUp();
          setVerifi(true);
        } else if (response.status === 400) {
          alert("user email already exists! try another email");
        }
      })
      .catch((error) => console.log(error));
  };

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const [conPass, setConPass] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //Confrim Password
  const handleConfrimPass = (prop) => (event) => {
    event.preventDefault();
    setConPass({ ...conPass, [prop]: event.target.value });
    setConfirmPassword(event.target.value);
  };

  const handleClickShowConfirmPassword = () => {
    setConPass({
      ...conPass,
      showPassword: !conPass.showPassword,
    });
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  // ----------------- Date of Birth ----------------//

  const currentYear = new Date().getFullYear();
  const backYears = currentYear - 99;

  let yearArray = [];
  let dateArray = [];
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  for (let i = backYears; i <= currentYear; i++) {
    yearArray.push(i);
  }

  for (let i = 1; i <= 31; i++) {
    dateArray.push(i);
  }

  // Token verification function
  const handleEmailVerify = () => {
    fetch(`${baseURL}/user/verify-otp`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        success: true,
      }),
    })
      .then((response) => {
        response.json();
        if (response.status === 404) {
          alert("Invalid PIN.Please try again");
        } else if (response.status === 500) {
          alert("Please, Enter the Valid PIN");
        } else if (response.status === 200) {
          alert("You Email Is Verified");
          setVerifi(false);
          ToggleSignUp();
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {activeStep === 0 ? (
        <Box sx={{ width: "100%", mt: 1 }}>
          <TextField
            label="Full Name"
            placeholder="Full name"
            className="w-100"
            value={firstName}
            key="0"
            onChange={(e) => setFirstName(e.target.value)}
          />

          <TextField
            label="Email Address"
            placeholder="email@address.com"
            className="w-100 my-2"
            value={email}
            key="1"
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Profession"
            placeholder="Student / Researcher / Any Profession"
            value={profession}
            className="w-100"
            onChange={(e) => setProfession(e.target.value)}
          />

          <TextField
            label="Academic Discipline"
            placeholder="SSC / HSC / Honours / Master's / Any Discipline"
            value={academic}
            className="w-100 mt-2"
            onChange={(e) => setAcademicDis(e.target.value)}
          />

          <TextField
            label="Institution"
            placeholder=" School / College / Univesity / Any Institution"
            value={organization}
            className="w-100 mt-2"
            onChange={(e) => setOrganization(e.target.value)}
          />
        </Box>
      ) : (
        <Box sx={{ width: "100%", mt: 2 }}>
          <TextField
            label="Phone Number"
            type="number"
            placeholder="01841779449"
            value={phone}
            className="w-100"
            onChange={(e) => setPhone(e.target.value)}
          />

          <Row
            container
            alignItems="center"
            justifyContent="center"
            className="g-2 my-2 d-flex align-items-center"
          >
            <Col md={6}>
              <FormControl variant="outlined" className="w-100">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </Col>
            <Col md={6}>
              <FormControl variant="outlined" className="w-100 my-1">
                <InputLabel htmlFor="outlined-adornment-password">
                  Confrim Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={conPass.showPassword ? "text" : "password"}
                  value={conPass.password}
                  onChange={handleConfrimPass("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                      >
                        {conPass.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confrim Password"
                />
              </FormControl>
            </Col>
          </Row>

          <Row className="g-2 d-flex justify-content-between">
            <InputLabel id="demo-simple-select-label">Date of Birth</InputLabel>
            <Col item sm={4} xs={6}>
              <TextField
                sx={{ minWidth: 105 }}
                value={monthOfBirth}
                onChange={(e) => setMonthOfBirth(e.target.value)}
                select
                label="Month"
              >
                {months.map((item, index) => (
                  <MenuItem key={index} value={index + 1}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col item sm={4} xs={6}>
              <TextField
                sx={{ minWidth: 100 }}
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
                select
                label="Day"
              >
                {dateArray.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
            <Col item sm={4}>
              <TextField
                sx={{ minWidth: 120 }}
                value={yearOfBirth}
                onChange={(e) => setYearOfBirth(e.target.value)}
                select
                label="Year"
              >
                {yearArray.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Col>
          </Row>

          <FormControl margin="normal" className="w-100">
            <FormLabel id="demo-row-radio-buttons-group-label">
              Gender
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e) => setGender(e.target.value)}
            >
              <FormControlLabel
                value="Female"
                control={<Radio />}
                label="Female"
              />
              <FormControlLabel value="Male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="Other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
          {/* <button onClick={() => setVerifi(true)}>verifi</button> */}
        </Box>
      )}

      <MobileStepper
        className="w-100 py-2"
        variant="dots"
        steps={2}
        position="static"
        activeStep={activeStep}
        nextButton={
          activeStep === maxSteps - 1 ? (
            <Button
              className={`${style.btn}`}
              variant="text"
              onClick={(e) => handleRegistration(e)}
            >
              Join <KeyboardArrowRight />
            </Button>
          ) : (
            <Button
              className={`${style.btn}`}
              size="small"
              onClick={handleNext}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          )
        }
        backButton={
          <Button
            className={`${style.btn}`}
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />

      <>
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={openVerifi}
          onClose={handleVerifiClose}
        >
          <DialogTitle
            className="d-flex flex-column align-items-center"
            style={{ backgroundColor: "blue" }}
          >
            <div className={`${style.rr_logo}`}>
              <Image
                src={logo}
                layout="fill"
                sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
                alt="research rider logo"
                title="research rider logo"
                priority
              />
            </div>
          </DialogTitle>

          <DialogContent>
            <Box>
              <div className="text-center">
                <picture>
                  <img
                    src="https://img.icons8.com/fluency/48/000000/reading-confirmation.png"
                    alt="email verification thumbnails"
                    title="email verification thumbnails"
                  />
                </picture>
                <strong className="d-block fs-5">
                  Verify Your Email Address
                </strong>
                <small className="text-break">
                  A verification code has been send, please check your inbox,
                  spam or promotion folder and enter the verification code below
                  to verify you email address. <br />
                  <span className="fw-bold">
                    If you miss to verify the verification code. Please try to
                    login the same email address.
                  </span>{" "}
                  Thank you.
                </small>
                <div className="d-flex justify-content-center pt-2">
                  <input
                    className="form-control"
                    style={{ width: "280px" }}
                    onChange={(e) => setToken(e.target.value)}
                  />
                </div>
                <Button
                  className="px-4 my-2"
                  onClick={handleEmailVerify}
                  variant="contained"
                  color="success"
                  type="submit"
                >
                  Verify
                </Button>
              </div>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleVerifiClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};

export default CourseSignUp;
