import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import style from "./ScrollNav.module.css";
import HomeIcon from "@mui/icons-material/Home";
import Button from "@mui/material/Button";
import GroupIcon from "@mui/icons-material/Group";
import SchoolIcon from "@mui/icons-material/School";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import Link from "next/link";
import { Container } from "react-bootstrap";
import SignUpModal from "../../ModalContent/SignUpModal/SignUpModal";

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};
export default function ScrollNav(props) {
  const { isLoggedIn } = props;
  //sign up modal
  const [openSingUp, setOpenSignUp] = React.useState(false);
  const [toggle, setToggle] = React.useState(false);

  const handleSignUpClose = () => {
    setOpenSignUp(false);
  };

  const handleSignUpOpen = () => {
    setOpenSignUp(true);
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar
          className={style.shadow}
          color="inherit"
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          <Container className={`${style.nav_list}`}>
            {isLoggedIn ? (
              <Link href="/group/home">
                <a>
                  <Button sx={{ fontWeight: "600", textTransform: "none" }}>
                    <GroupIcon sx={{ fontSize: "18px", mx: 0.5 }} />
                    Group
                  </Button>
                </a>
              </Link>
            ) : (
              <Button
                onClick={() => {
                  setOpenSignUp(true);
                  setToggle(true);
                }}
                sx={{ fontWeight: "600", textTransform: "none" }}
              >
                <GroupIcon sx={{ fontSize: "18px", mx: 0.5 }} />
                Group
              </Button>
            )}

            <Link href="/course">
              <a>
                <Button sx={{ fontWeight: "600", textTransform: "none" }}>
                  <SchoolIcon sx={{ fontSize: "18px", mx: 0.5 }} />
                  Course
                </Button>
              </a>
            </Link>

            <Link href="/prtp">
              <a>
                <Button sx={{ fontWeight: "600", textTransform: "none" }}>
                  <CastForEducationIcon sx={{ fontSize: "18px", mx: 0.5 }} />
                  PRTP
                </Button>
              </a>
            </Link>

            <Link href="/ictsolution">
              <a>
                <Button sx={{ fontWeight: "600", textTransform: "none" }}>
                  <TravelExploreIcon sx={{ fontSize: "18px", mx: 0.5 }} />
                  ICT Soluation
                </Button>
              </a>
            </Link>

            <Link href="/research-solutions">
              <Button
                sx={{
                  fontWeight: "600",
                  textTransform: "none",
                  display: { xs: "none", sm: "none", md: "block" },
                }}
              >
                <PlagiarismOutlinedIcon sx={{ fontSize: "18px", mx: 0.5 }} />
                Research Solution
              </Button>
            </Link>
          </Container>
        </AppBar>
      </HideOnScroll>
      <SignUpModal
        toggle={toggle}
        open={openSingUp}
        handleClose={handleSignUpClose}
        handleOpen={handleSignUpOpen}
      />
    </React.Fragment>
  );
}
