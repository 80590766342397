import React from "react";
import { Container } from "react-bootstrap";
import Popover from "@mui/material/Popover";
import { Avatar, Paper, Button, Stack } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

const user = "https://www.port.ac.uk/-/media/pure/people/16101009.jpg";

const Notification = ({ handleClose, handleClick, id, open, anchorEl }) => {
  return (
    <Container>
      <div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {Array.from({ length: 6 }).map((_, idx) => (
            <div key={idx} className="mb-2">
              <Paper className="p-3 rounded-4">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <Avatar
                    alt={user.first_name}
                    src={user}
                    sx={{ width: 45, height: 45 }}
                  />
                  <span className="text-primary mx-2 fw-bold">Group Name</span>
                  <span className="mx-2">
                    Sent you an Invitation to join as
                  </span>
                  <span className="mx-2 text-info fw-bold">Teacher</span>

                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ borderRadius: 28 }}
                      color="error"
                      endIcon={<HighlightOffOutlinedIcon />}
                    >
                      Decline
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="success"
                      sx={{ borderRadius: 28 }}
                      endIcon={<CheckCircleOutlineOutlinedIcon />}
                    >
                      Approve
                    </Button>
                  </Stack>
                </div>
              </Paper>
            </div>
          ))}
        </Popover>
      </div>
    </Container>
  );
};

export default Notification;
