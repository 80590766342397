import Link from "next/link";
import React from "react";
import { Row, Col } from "react-bootstrap";
import style from "./GroupFooter.module.css";

const GroupFooter = () => {
  return (
    <div className={`${style.main_container} container-fluid`}>
      <div className={`${style.footer_body} container d-flex flex-column`}>
        <div>Research Rider</div>

        <div className={`${style.bar} my-3`}></div>

        <Row className="d-flex flex-wrap justify-content-start g-2">
          <Col xs={3} sm={3} md={12} xl={3}>
            <Link
              className="mx-2 my-1 text-decoration-none text-muted w-25"
              href="/our/about"
            >
              About
            </Link>
          </Col>
          <Col xs={3} sm={3} md={12} xl={3}>
            <Link
              className="mx-2 my-1 text-decoration-none text-muted"
              href="/ictsolution"
            >
              Services
            </Link>
          </Col>
          <Col xs={6} sm={6} md={12} xl={6}>
            <Link
              className="mx-2 my-1 text-decoration-none text-muted"
              href="/our/privacy-policy"
            >
              Privacy Policy
            </Link>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12}>
            <Link
              className="mx-2 my-1 text-decoration-none text-muted"
              href="/our/CookeyPolicy"
            >
              Cookie Policy
            </Link>
          </Col>
        </Row>

        <div className="my-3">
          <span>Research Rider &copy; 2023 </span>
        </div>
      </div>
    </div>
  );
};

export default GroupFooter;
