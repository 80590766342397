import React from "react";
import style from "./Login.module.css";
import class_icon from "../../../assets/re/20.svg";
import group_icon from "../../../assets/re/16.svg";
import logo from "../../../assets/landing/logo.svg";
import Image from "next/image";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Notification from "../../../Components/Notification";
import * as constants from "../../../Components/constants";
import Link from "next/link";
import { Avatar, Paper } from "@mui/material";
import "burger-menu/lib/index.css";
import Hamburger from "hamburger-react";
import Hamber from "../Hamber/Hamber";
import { useRouter } from "next/router";

const BASE_URL = constants.BASE_URL;

const LoginHeader = ({ isLoggedIn }) => {
  //Hamber
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user, setUser] = React.useState("");

  const router = useRouter();
  //Handle Google Search
  const handleSearch = (event) => {
    event.preventDefault();
    const searchTerm = event.target.search.value;
    router.push(`/search?q=${searchTerm}`);
  };

  // End searchbar
  React.useEffect(() => {
    fetch(`${BASE_URL}/user/user-general-info/${localStorage.getItem("id")}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("auth_token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setUser(data[0]);
      });
  }, [localStorage.getItem("id")]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Paper
        elevation={0}
        className={`${style.navbar_main_container} d-flex justify-content-between align-items-center`}
      >
        <Link href="/" passHref>
          <a>
            <Image
              className="p-1"
              width={130}
              height={52}
              src={logo}
              alt="research rider logo"
              priority
            />
          </a>
        </Link>

        <div
          style={{ backgroundColor: "rgb(213 228 251)" }}
          className={`${style.nav__search}`}
        >
          <form onSubmit={handleSearch} className="d-flex">
            <SearchOutlinedIcon />
            <input
              name="search"
              type="text"
              placeholder="Search Research Rider"
            />
          </form>
        </div>

        <div className={`d-flex align-items-center`}>
          <div className={`${style.mid_section} `}>
            <Link href="/group/home" passHref>
              <a>
                <Image
                  src={group_icon}
                  height={38}
                  width={50}
                  className={`material-icons ${style.icons}`}
                  alt="research rider group"
                  priority
                />
              </a>
            </Link>

            <Link href="/course" passHref>
              <a>
                <Image
                  src={class_icon}
                  height={38}
                  width={50}
                  className={`material-icons ${style.icons}`}
                  alt="research rider class"
                  priority
                />
              </a>
            </Link>
          </div>

          {isLoggedIn === true && (
            <a href={`/user/${localStorage.getItem("id")}`}>
              <Avatar
                sx={{
                  width: 34,
                  height: 34,
                  border: 1,
                  borderColor: "grey.500",
                }}
                src={user?.profile_pic}
                alt="user profile"
              />
            </a>
          )}

          <span className={`${style.nav_hamber}`}>
            <Hamburger
              className={`${style.hamber}`}
              toggled={isOpen}
              toggle={setIsOpen}
              size={21}
            />
          </span>
        </div>
      </Paper>

      <>
        <Hamber isOpen={isOpen} setIsOpen={setIsOpen} />
        <Notification
          handleClick={handleClick}
          handleClose={handleClose}
          open={open}
          id={id}
          anchorEl={anchorEl}
        />
      </>
    </>
  );
};

export default LoginHeader;
