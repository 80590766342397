import React from "react";
import { Menu } from "burger-menu";
import "burger-menu/lib/index.css";
import style from "./Hamber.module.css";
import { Button } from "react-bootstrap";
import { useRouter } from "next/router";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { List } from "@mui/material";
import Link from "next/link";
import GroupIcon from "@mui/icons-material/Group";
import SchoolIcon from "@mui/icons-material/School";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";
import EventIcon from "@mui/icons-material/Event";

const Hamber = ({ isOpen, setIsOpen }) => {
  const router = useRouter();
  const logOut = () => {
    window.localStorage.clear();
    router.push("/");
  };

  const listItems = [
    {
      listIcon: <SchoolIcon />,
      listText: "Courses",
      link: "/course",
    },
    {
      listIcon: <GroupIcon />,
      listText: "Group Home",
      link: "/group/home",
    },
    {
      listIcon: <TravelExploreIcon />,
      listText: "ICT Soluation",
      link: "/ictsolution",
    },
    {
      listIcon: <PlagiarismIcon />,
      listText: "Research Solution",
      link: "/research-solutions",
    },
    {
      listIcon: <CastForEducationIcon />,
      listText: "PRTP",
      link: "/prtp",
    },
    // {
    //   listIcon: <EventIcon />,
    //   listText: "Event",
    //   link: "/event",
    // },
  ];

  return (
    <React.Fragment>
      <Menu
        width={300}
        className={`burger-menu ${style.element} rounded-2 p-0 m-0`}
        isOpen={isOpen}
        selectedkey={"entry"}
        onClose={() => setIsOpen(false)}
      >
        <List>
          {listItems.map((listItem, index) => (
            <Link href={listItem.link} key={index}>
              <ListItemButton
                onClick={() => setIsOpen(false)}
                component="a"
                sx={{ py: 0, minHeight: 32, color: "rgba(255,255,255,.8)" }}
              >
                <ListItemIcon sx={{ color: "#0572e6", width: 24, height: 24 }}>
                  {listItem.listIcon}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: 14,
                    fontWeight: "600",
                  }}
                  primary={listItem.listText}
                />
              </ListItemButton>
            </Link>
          ))}
        </List>

        <div className="text-center py-4">
          <Button variant="danger" size="sm" onClick={() => logOut()}>
            Logout
          </Button>
        </div>
      </Menu>
    </React.Fragment>
  );
};

export default Hamber;
