export default {
  title: "Research Rider | Grow up Being a Researcher",
  description:
    "Research Rider provides various products, services, and supports which are highly based on research and ICT platforms.",
  canonical: "https://www.researchrider.com/",

  openGraph: {
    type: "Be connected with the worldwide research hub.",
    locale: "en_IE",
    url: "https://www.researchrider.com/",
    siteName: "Research Rider",

    images: [
      {
        url: "https://i.ibb.co/09jksqk/meta-Img.png",
        width: 800,
        height: 600,
        alt: "Og Image Alt",
      },
    ],
  },
  twitter: {
    handle: "@Research Rider",
    site: "@ResearchRider",
    cardType: "summary_large_image",
  },
};
