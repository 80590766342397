import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import SearchIcon from "@mui/icons-material/Search";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import GroupIcon from "@mui/icons-material/Group";
import SchoolIcon from "@mui/icons-material/School";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import MenuIcon from "@mui/icons-material/Menu";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import LoginIcon from "@mui/icons-material/Login";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import logo from "../../../assets/landing/logo.svg";
import * as constants from "../../../Components/constants";
import Image from "next/image";
import Link from "next/link";
import { Avatar, Chip } from "@mui/material";
import { Stack } from "@mui/system";
import GroupFooter from "../../Group/GroupFooter/GroupFooter";
import style from "./Header.module.css";
import { useRouter } from "next/router";

const drawerWidth = 240;
const BASE_URL = constants.BASE_URL;

function Header(props) {
  const [user, setUser] = React.useState("");
  const router = useRouter();
  const { window, isLoggedIn } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <div className="d-flex align-items-center justify-content-center">
        <Link href="/" passHref>
          <a>
            <Image
              src={logo}
              width={120}
              height={65}
              alt="logo of rr"
              title="research rider"
              priority
            />
          </a>
        </Link>
      </div>

      <Divider sx={{ borderBottom: "1px solid gray" }} />
      <List>
        <ListItem disablePadding>
          <ListItemButton className="d-flex flex-column align-items-start">
            {isLoggedIn === true && (
              <Link href={`/user/${localStorage.getItem("id")}`}>
                <Button
                  sx={{
                    fontWeight: "600",
                    mb: 2,
                    borderBottom: "1px solid gray",
                  }}
                  className="d-flex flex-column"
                >
                  <Avatar
                    sx={{
                      width: 50,
                      height: 50,
                      border: 1,
                      mx: 9,
                      borderColor: "grey.500",
                    }}
                    src={user?.profile_pic}
                    alt="home"
                  />
                </Button>
              </Link>
            )}

            <Link href="/course">
              <Button sx={{ fontWeight: "600" }}>
                <SchoolIcon
                  className="mx-1 border rounded-pill p-1"
                  style={{ fontSize: "30px" }}
                />
                Course
              </Button>
            </Link>
            <Link href="/group/home">
              <Button sx={{ fontWeight: "600" }}>
                <GroupIcon
                  className="mx-1 border rounded-pill p-1"
                  style={{ fontSize: "30px" }}
                />{" "}
                Group
              </Button>
            </Link>

            <Link href="/prtp">
              <Button sx={{ fontWeight: "600" }}>
                <CastForEducationIcon
                  className="mx-1 border rounded-pill p-1"
                  style={{ fontSize: "30px" }}
                />{" "}
                PRTP
              </Button>
            </Link>
            <Stack direction="row" spacing={5} className="pt-4 mx-2">
              {isLoggedIn === false && (
                <Link href="/user/login">
                  <Chip
                    color="primary"
                    icon={<LoginIcon />}
                    label="Login"
                    variant="outlined"
                  />
                </Link>
              )}
              {isLoggedIn === false && (
                <Link href="/user/registration">
                  <Chip
                    color="success"
                    icon={<HowToRegIcon />}
                    label="Join"
                    variant="outlined"
                  />
                </Link>
              )}
            </Stack>
          </ListItemButton>
        </ListItem>
      </List>
      <GroupFooter />
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  // Getting Login User
  React.useEffect(() => {
    fetch(`${BASE_URL}/user/user-general-info/${localStorage.getItem("id")}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${localStorage.getItem("auth_token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setUser(data[0]);
      });
  }, []);

  //Google Search bar
  const [searchEl, setSearchEl] = React.useState(null);

  const handleClickSearch = (event) => {
    setSearchEl(event.currentTarget);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const searchTerm = event.target.search.value;
    router.push(`/search?q=${searchTerm}`);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar color="inherit" className="py-2" sx={{ boxShadow: "none" }}>
          <Toolbar className="d-flex justify-content-between">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 12, display: { sm: "none" } }}
            >
              <MenuIcon sx={{ color: "#166edf", fontSize: "40px" }} />
            </IconButton>

            <Link href="/" passHref>
              <a>
                <Image
                  width={150}
                  height={75}
                  src={logo}
                  alt="research rider logo"
                  priority
                />
              </a>
            </Link>

            <form className={style.search} onSubmit={handleSearch}>
              <input
                name="search"
                type="text"
                className={style.searchTerm}
                placeholder="Serach Research Rider"
              />
              <button
                type="submit"
                className={style.searchButton}
                onClick={handleClickSearch}
              >
                <SearchIcon />
              </button>
            </form>

            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "block",
                  md: "flex",
                },
              }}
            >
              {isLoggedIn === false && (
                <Link href="/user/login">
                  <Chip
                    className={`${style.signIn}`}
                    variant="outlined"
                    color="primary"
                    label="Sign in"
                  />
                </Link>
              )}

              {isLoggedIn === false && (
                <Link href="/user/registration">
                  <Chip
                    className={`${style.signUp} mx-2`}
                    variant="outlined"
                    color="success"
                    label="Sign up"
                  />
                </Link>
              )}

              {isLoggedIn && (
                <Link href={`/user/${localStorage.getItem("id")}`}>
                  <Avatar
                    sx={{
                      width: 35,
                      height: 35,
                      border: 1,
                      borderColor: "gray",
                    }}
                    src={user.profile_pic}
                    alt="user"
                  />
                </Link>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </>
  );
}

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;
