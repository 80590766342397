import { useRouter } from "next/router";
import React from "react";
import LoginHeader from "./Shared/LoginHeader/LoginHeader";
import Header from "./Landing/Header/Header";
import ScrollNav from "./Shared/ScrollNav/ScrollNav";

const Layout = ({ children, isLoggedIn }) => {
  const router = useRouter();
  const excludePaths = [
    "/user/login",
    "/user/registration",
    "/user/verification",
    "/our/privacy-policy",
    "/our/CookeyPolicy",
    "/prtp",
    "/search/results",
  ];

  const shouldRenderHeader = !excludePaths.includes(router.pathname);

  return (
    <>
      {isLoggedIn && shouldRenderHeader && (
        <LoginHeader isLoggedIn={isLoggedIn} />
      )}
      {!isLoggedIn && shouldRenderHeader && (
        <>
          <Header isLoggedIn={isLoggedIn} />
          <ScrollNav />
        </>
      )}
      {children}
    </>
  );
};

export default Layout;
